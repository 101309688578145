
/*
 *   File : remove-rm.js
 *   Author URI : https://evoqins.com
 *   Description : Screen to remove zone, rm, region, branch.
 *   Integrations : null
 *   Version : v1.1
 */

import { useEffect, useState } from "react";
import { toast } from "react-toastify";

import { GradientButton, OutlineButton } from "../Cta";
import { Icon } from "../Icon";
import { CustomSearchSelectBox } from "../FormElements";

import style from "../../Styles/Components/delete.module.scss";

import { closeModal } from "../../Helper/helper-function";
import APIService from "../../Services/api-service";


export default function RemoveRMModal(props) {
    const [apiLoader, setApiLoader] = useState(false);
    const [selectedZone, setSelectedZone] = useState({});
    const [selectedZoneError, setSelectedZoneError] = useState("");
    const [selectedRegion, setSelectedRegion] = useState({});
    const [selectedRegionError, setSelectedRegionError] = useState("");
    const [selectedBranch, setSelectedBranch] = useState({});
    const [selectedBranchError, setSelectedBranchError] = useState("");
    const [selectedRM, setSelectedRM] = useState({});
    const [selectedRmError, setSelectedRmError] = useState("");
    const [zoneList, setZoneList] = useState(props.zoneList);
    const [regionList, setRegionList] = useState([]);
    const [branchList, setBranchList] = useState([]);
    const [rmList, setRmList] = useState([]);

    useEffect(() => {
        var my_modal = document.getElementById("remove-rm");
        my_modal.addEventListener("hidden.bs.modal", () => {
            props.close();
        });
        //eslint-disable-next-line
    }, [props.close]);

    useEffect(() => {
        if (props.zoneList.length) {
            const zones = props.zoneList
                .filter(item => item.value !== props.id);
            setZoneList(zones);
        }
    }, [props.zoneList]);


    const _handleCancel = () => {
        props.close();
    }

    function _validateForm() {
        let valid = true;
        if (props.type === 1 &&
            props.remapAvailable === true &&
            Object.keys(selectedZone).length === 0) {
            setSelectedZoneError("Please select zone");
            valid = false;
        }
        if (props.type === 2 &&
            props.remapAvailable === true &&
            Object.keys(selectedZone).length === 0 &&
            Object.keys(selectedRegion).length === 0) {
            setSelectedZoneError("Please select zone");
            setSelectedRegionError("Please select region");
            valid = false;
        }
        if (props.type === 3 &&
            props.remapAvailable === true &&
            Object.keys(selectedZone).length === 0 &&
            Object.keys(selectedRegion).length === 0 &&
            Object.keys(selectedBranch).length === 0) {
            setSelectedZoneError("Please select zone");
            setSelectedRegionError("Please select region");
            setSelectedBranchError("Please select branch")
            valid = false;
        }
        if (props.type === 4 &&
            props.remapAvailable === true &&
            Object.keys(selectedZone).length === 0 &&
            Object.keys(selectedRegion).length === 0 &&
            Object.keys(selectedBranch).length === 0 &&
            Object.keys(selectedRM).length === 0) {
            setSelectedZoneError("Please select zone");
            setSelectedRegionError("Please select region");
            setSelectedBranchError("Please select branch");
            setSelectedRM("Please select RM");
            valid = false;
        }
        return valid;
    }

    async function _handleButtonClick() {
        let valid_form = await _validateForm();
        if (valid_form === false) {
            return;
        }
        setApiLoader(true);
        if (props.type === 1) {
            _removeZone()
        } else if (props.type === 2) {
            _removeRegion()
        } else if (props.type === 3) {
            _removeBranch();
        } else {
            _removeRM();
        }
    }

    // API - remove zone
    function _removeZone() {
        let url = 'client_mapping/zone/delete';
        let payload = JSON.stringify({
            id: props.id,
            zone_id: props.type === 1 && props.remapAvailable === true ? selectedZone.value : null
        });
        APIService(true, url, payload).then((response) => {
            if (response.status_code === 200) {
                toast.dismiss();
                toast.success("Zone removed successfully");
                closeModal('remove-rm');
                props.confirmDelete(1);
            } else {
                toast.dismiss();
                toast.error(response.message);
            }
            setApiLoader(false);
        })
    }

    // API - remove zone
    function _removeRegion() {
        let url = 'client_mapping/region/delete';
        let payload = JSON.stringify({
            id: props.id,
            region_id: props.type === 2 && props.remapAvailable === true ? selectedRegion.value : null
        });
        APIService(true, url, payload).then((response) => {
            if (response.status_code === 200) {
                toast.dismiss();
                toast.success("Region removed successfully");
                closeModal('remove-rm');
                props.confirmDelete(2);
            } else {
                toast.dismiss();
                toast.error(response.message);
            }
            setApiLoader(false);
        })
    }

    // API - remove zone
    function _removeBranch() {
        let url = 'client_mapping/branch/delete';
        let payload = JSON.stringify({
            id: props.id,
            branch_id: props.type === 3 && props.remapAvailable === true ? selectedBranch.value : null
        });
        APIService(true, url, payload).then((response) => {
            if (response.status_code === 200) {
                toast.dismiss();
                toast.success("Branch removed successfully");
                closeModal('remove-rm');
                props.confirmDelete(3);
            } else {
                toast.dismiss();
                toast.error(response.message);
            }
            setApiLoader(false);
        })
    }

    // API - remove zone
    function _removeRM() {
        let url = 'client_mapping/rm/delete';
        let payload = JSON.stringify({
            id: props.id,
            rm_id: props.type === 4 && props.remapAvailable === true ? selectedRM.value : null
        });
        APIService(true, url, payload).then((response) => {
            if (response.status_code === 200) {
                toast.dismiss();
                toast.success("RM removed successfully");
                closeModal('remove-rm');
                props.confirmDelete(4);
            } else {
                toast.dismiss();
                toast.error(response.message);
            }
            setApiLoader(false);
        })
    }

    // API - get region data based on zone
    function _getRegionData(id) {
        let url = 'client_mapping/region/list';
        let payload = JSON.stringify({
            id
        });
        APIService(true, url, payload).then((response) => {
            if (response.status_code === 200) {
                const modified_data = response.data.map((item) => {
                    return {
                        value: item.id,
                        label: item.name
                    }
                });
                const regions = modified_data
                .filter(item => item.value !== props.id);
                setRegionList(regions);
            } else {
                setRegionList([])
            }
        })
    }

    function _getBranchList(id) {
        let url = 'client_mapping/branch/list';
        let payload = JSON.stringify({
            id
        });
        APIService(true, url, payload).then((response) => {
            if (response.status_code === 200) {
                const modified_data = response.data.map((item) => {
                    return {
                        value: item.id,
                        label: item.name
                    }
                })
                const branches = modified_data
                .filter(item => item.value !== props.id);
                setBranchList(branches);
            } else {
                setBranchList([])
            }

        })
    }

    function _getRMList(id) {
        let url = 'client_mapping/rm/list';
        let payload = JSON.stringify({
            id: selectedRM.value
        });
        APIService(true, url, payload).then((response) => {
            if (response.status_code === 200) {
                const modified_data = response.data.map((item) => {
                    return {
                        value: item.id,
                        label: item.name
                    }
                });
                const rms = modified_data
                .filter(item => item.value !== props.id);
                setRmList(rms);
            } else {
                setRmList([])
            }

        })
    }

    const _selectZone = (zone) => {
        if (props.type > 1) {
            _getRegionData(zone.value);
        }
        setSelectedZone(zone);
        setSelectedRegion({});
        setSelectedBranch({});
        setSelectedZoneError("");
    }

    const _selectRegion = (region) => {
        if (props.type > 2) {
            _getBranchList(region.value);
        }
        setSelectedRegion(region);
        setSelectedBranch({});
        setSelectedRegionError("");
    }

    const _selectBranch = (branch) => {
        if (props.type > 3) {
            _getRMList(branch.value)
        }
        setSelectedBranch(branch);
        setSelectedBranchError("");
    }

    const _selectRm = (rm) => {
        setSelectedRM(rm);
        setSelectedRmError("");
    }

    return (
        <div className={`modal fade ${style.e_delete}`}
            id="remove-rm"
            tabIndex="-1"
            aria-labelledby="remove-rm"
            aria-hidden="true"
            data-bs-backdrop="true"
            inert>
            <div className={`modal-dialog position-relative modal-dialog-centered ${style.e_dialog}`}>
                <div className={`modal-content bg-white text-center d-flex align-items-center padding-32px-all ${style.e_content} `}>
                    <div className="col-12 d-flex justify-content-between">

                        <Icon icon="close"
                            size={24}
                            className="cursor-pointer ms-auto e-modal-close-btn"
                            data-bs-dismiss="modal" />
                    </div>
                    <img draggable={false} src={require("../../Assets/Images/Modal/delete-icon.png")}
                        alt="Delete account"
                        width={72}
                        height={72}
                    />


                    <p className="color-eerie-black e-poppins-medium e-font-18 mt-4 mb-0">
                        {
                            props.type === 1 ?
                                "Are you sure want to delete this zone?"
                                : props.type === 2 ?
                                    "Are you sure want to delete this region?"
                                    : props.type === 3 ?
                                        "Are you sure want to delete this branch?"
                                        :
                                        "Are you sure want to delete this RM?"
                        }
                    </p>
                    {
                        props.remapAvailable === true && (
                            <div className="row">
                                <p>Remap available please select another admin to proceed with delete</p>

                                <div className="col-12 text-start">
                                    <CustomSearchSelectBox options={zoneList}
                                        value={Object.keys(selectedZone).length === 0 ? "" : selectedZone}
                                        label="Select zone"
                                        error={selectedZoneError}
                                        id="admin-zone"
                                        hide_error_text={false}
                                        placeholder=""
                                        postfix="*"
                                        onSelectChange={_selectZone} />
                                </div>
                                {
                                    props.type >= 2 && regionList.length > 0 &&
                                    <div className="col-12 mt-4 text-start">
                                        <CustomSearchSelectBox options={regionList}
                                            value={Object.keys(selectedRegion).length === 0 ? "" : selectedRegion}
                                            label="Select Region"
                                            error={selectedRegionError}
                                            id="admin-zone"
                                            hide_error_text={false}
                                            placeholder=""
                                            postfix="*"
                                            onSelectChange={_selectRegion} />
                                    </div>
                                }
                                {
                                    props.type >= 3 && branchList.length > 0 &&
                                    <div className="col-12 mt-4 text-start">
                                        <CustomSearchSelectBox options={branchList}
                                            value={Object.keys(selectedBranch).length === 0 ? "" : selectedBranch}
                                            label="Select Branch"
                                            error={selectedBranchError}
                                            id="admin-zone"
                                            hide_error_text={false}
                                            placeholder=""
                                            postfix="*"
                                            onSelectChange={_selectBranch} />
                                    </div>
                                }
                                {
                                    props.type === 4 && rmList.length > 0 &&
                                    <div className="col-12 mt-4 text-start">
                                        <CustomSearchSelectBox options={rmList}
                                            value={Object.keys(selectedRM).length === 0 ? "" : selectedRM}
                                            label="Select RM"
                                            error={selectedRmError}
                                            id="admin-zone"
                                            hide_error_text={false}
                                            placeholder=""
                                            postfix="*"
                                            onSelectChange={_selectRm} />
                                    </div>
                                }
                            </div>
                        )
                    }

                    <div className="d-flex gap-8px margin-34px-top margin-32px-bottom  w-100 px-5">
                        <OutlineButton label="No, cancel"
                            cancel="modal"
                            className="padding-12px-tb px-3 white-space-nowrap  e-font-16 e-poppins-regular "
                            handleClick={_handleCancel} />
                        <GradientButton label="Yes, delete"
                            className="padding-12px-tb e-font-16 w-100 "
                            // cancel="modal"
                            loading={apiLoader}
                            onPress={_handleButtonClick} />
                    </div>
                </div>
            </div>
        </div>
    )
}