/*
 *   File : mandates.js
 *   Author URI : https://evoqins.com
 *   Description : Mandate listing page
 *   Integrations : null
 *   Version : v1.1
 */

import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { toast } from "react-toastify";
import { Modal } from "bootstrap";

import { DataTableContainer } from "../../../../Components/FormElements";
import { CustomLoader, EmptyScreen } from "../../../../Components/Other";
import { GradientButton, OutlineButton } from "../../../../Components/Cta";
import { CreateMandateModal, UploadSignedMandate, SharePhysicalMandate } from "../../../../Components/Modal";
import { CustomTab } from "../../../../Components/Tab";
import { Icon } from "../../../../Components/Icon";
import APIService from "../../../../Services/api-service";

import Colors from "../../../../Styles/color.module.scss";

const MANDATE_TYPE = [
    "E-mandate",
    "Physical mandate"
]

const MandateList = React.memo((props) => {

    const location = useLocation();
    const [mandateList, setMandateList] = useState([]);
    const [loader, setLoader] = useState(true);
    const [showMandateModal, setShowMandateModal] = useState(false);
    const [mandateType, setMandateType] = useState(1);
    const [selectedMandate, setSelectedMandate] = useState("");
    const [openUploadDocModal, setOpenUploadDocModal] = useState(false);
    const [openShareModal, setOpenShareModal] = useState(false);
    const [selectedFormUrl, setSelectedFormUrl] = useState('');

    useEffect(() => {
        _getMandateList();
    }, [mandateType]);

    useEffect(() => {
        if (openUploadDocModal === true) {
            const modal = new Modal(document.getElementById("upload-mandate"), {});
            modal.toggle();
        }
    }, [openUploadDocModal]);

    useEffect(() => {
        if (openShareModal === true) {
            const modal = new Modal(document.getElementById("share-physical-mandate"), {});
            modal.toggle();
        }
    }, [openShareModal]);

    useEffect(() => {
        if (showMandateModal === true) {
            const modal = new Modal(document.getElementById("show-mandate"), {});
            modal.show();
        }
    }, [showMandateModal]);

    const _handleRowHover = (row) => {

    }

    const _handleRowRemoveHover = () => {

    }

    const _handleCardClick = (row) => {

    }

    const MANDATE_COLUMNS = [
        {
            name: 'Mandate bank',
            selector: row => row['bank_name'],
            sortable: true,
            left: true,
            // width: "576px",
            maxWidth: "200px",
            cell: row =>
                <div className="d-flex align-items-center gap-12px e-fund-name ps-2" onClick={() => _handleCardClick(row)}>
                    <p className="color-primary-color e-poppins-regular e-font-16 line-height-24px mb-0 bg-white mt-0">{row['bank_name']}</p>
                </div>
        },
        {
            name: 'Mandate ID',
            selector: row => row['mandate_id'],
            sortable: false,
            left: true,
            maxWidth: "160px",
            cell: row => <span onClick={() => _handleCardClick(row)}> {row['mandate_id']}</span>,
        },
        {
            name: 'Mandate created on',
            selector: row => row['created'],
            sortable: true,
            left: true,
            maxWidth: "180px",
            cell: row =>
                <p className="color-black e-poppins-regular e-font-16 line-height-24px mb-0 mt-0 gap-12px" onClick={() => _handleCardClick(row)}>
                    {row['created']}
                </p>
        },
        {
            name: 'Mandate expiry date',
            selector: row => row['end_date'],
            sortable: true,
            left: true,
            maxWidth: "180px",
            cell: row => <span onClick={() => _handleCardClick(row)}>
                {row['end_date']}</span>,
        },
        {
            name: 'Mandate limit',
            selector: row => row['mandate_limit'],
            sortable: true,
            left: true,
            maxWidth: "150px",
            cell: row => <span onClick={() => _handleCardClick(row)}>
                <span className="e-inter-regular">₹</span>
                {row['mandate_limit']}</span>,
        },

        {
            name: 'Status',
            selector: row => row['status'],
            sortable: false,
            left: true,
            cell: row =>
                <div onClick={() => _handleCardClick(row)}>
                    <p className={` ${row.status_str === 'SUCCESS' ? 'color-sea-green' : 'color-vivid-orange'} e-poppins-medium e-font-16 line-height-24px mb-0 mt-0 e-mandate-status`}>
                        {row.status_str}
                    </p>
                </div>
        },
        {
            name: 'Remarks',
            selector: row => row['remarks'],
            sortable: false,
            left: true,
            cell: row =>
                <div onClick={() => _handleCardClick(row)}>
                    <p className="color-black e-poppins-regular e-font-16 line-height-24px mb-0 mt-0 gap-12px" onClick={() => _handleCardClick(row)}>
                        {row['remarks'] ? row['remarks'] : '-'}
                    </p>
                </div>
        },

    ];

    const TABLE_STYLE = {
        table: {
            style: {
                padding: "0 16px 16px 16px",
                background: Colors.white,
            },
        },
        tableBody: {
            style: {
                margin: '0px',
                marginTop: "0px !important"
            }
        },
        headRow: {
            style: {
                background: 'transparent',
                borderRadius: "16px 16px 0px 0px",
                border: "0",
                borderBottom: `1px solid ${Colors.bright_gray} !important`,
                fontSize: "14px",
                fontFamily: "Poppins-Medium",
                lineHeight: "24px"
            }
        },
        rows: {
            style: {
                margin: "0",
                background: "transparent",
                border: `1px solid ${Colors.bright_gray} !important`,
                borderRadius: "16px",
                marginTop: "14px",
                fontSize: "16px",
                fontFamily: "Poppins-Regular",
                lineHeight: "24px",
                cursor: "pointer",
                '&:hover': {
                    boxShadow: '0px 12px 16px 0px rgba(50, 50, 71, 0.08)',
                },
            },
        },
        cells: {
            style: {
                '&:not(:last-child)': {
                    paddingLeft: '0px',
                    paddingRight: '0px',
                    display: 'flex',
                    justifyContent: `start !important`
                },
                '&:first-child': {
                    padding: "24px 0px 24px 24px",
                }
            },
        },
        headCells: {
            style: {
                '&:not(:last-child)': {
                    paddingLeft: '0px',
                    paddingRight: '0px',
                    display: 'flex',
                    justifyContent: `start !important`
                },
                '&:first-child': {
                    padding: "24px 0px 24px 24px",
                }
            },
        },
        pagination: {
            style: {
                borderTop: `none `,
                color: Colors.outer_space,
                fontSize: '16px',
                fontFamily: "Poppins-Regular",
            },
        }
    }

    function _handleTab(value) {
        setMandateType(value);
    }

    function _handleMandateUploadButton(id) {
        setSelectedMandate(id);
        setOpenUploadDocModal(true);
    }

    function _handleReSentButton(id, url) {
        setSelectedMandate(id);
        setSelectedFormUrl(url);
        setOpenShareModal(true);
    }

    function _updateMandateStatus() {
        setOpenUploadDocModal(false);
        setSelectedMandate("");
        _getMandateList(2);
    }

    // API - Get user data
    function _getMandateList(type = mandateType) {
        let url = 'user/mandates/list';
        let payload = JSON.stringify({
            customer_id: location.state.user_id,
            bank_id: null,
            mandate_type: type
        });
        APIService(true, url, payload).then((response) => {
            if (response.status_code === 200) {
                setMandateList(response.data);
            }
            else {
                setMandateList([]);
            }
            setLoader(false);
        })
    }

    // handle create mandate button click
    function _handleMandateButton() {
        setShowMandateModal(true);
    }

    // handler to open mandate modal
    const _showMandateModal = () => {
        setShowMandateModal(true)
    }


    return (
        <>
            <div className="mt-3">
                <CustomTab data={MANDATE_TYPE}
                    index={mandateType}
                    onSelectTab={_handleTab} />
            </div>
            {
                loader === true ?
                    <div className="h-80vh">
                        <CustomLoader />
                    </div>
                    :

                    mandateList.length !== 0 ?
                        <>
                            {
                                mandateType === 1 ?
                                    <div className="col-12">
                                        <DataTableContainer columns={MANDATE_COLUMNS}
                                            data={mandateList}
                                            selectableRows={false}
                                            pagination={false}
                                            customStyles={TABLE_STYLE}
                                            onRowMouseEnter={_handleRowHover}
                                            defaultSortFieldId={1}
                                            onRowMouseLeave={_handleRowRemoveHover}

                                            rowClick={(row) => {
                                                _handleCardClick(row)
                                            }} />
                                        <GradientButton label="Create mandate"
                                            className="mt-3"
                                            onPress={_handleMandateButton} />
                                    </div>
                                    :
                                    <div className="row mt-lg-0 mt-md-0 mt-3">
                                        {/* <div className="col-12 mt-3">
                                            <h6 className="e-poppins-medium e-font-16 color-jett-black">Added physical mandate</h6>
                                        </div> */}
                                        {
                                            mandateList.map((item, key) => {
                                                return (
                                                    <div className="col-12 mt-3">

                                                        <div className="border-all border-bright-gray border-radius-16px padding-16px-lr padding-24px-tb">
                                                            <div className="row">
                                                                <div className="col border-right-1px pe-4">
                                                                    <label className="e-poppins-medium e-font-14 color-outer-space">Added on</label>
                                                                    <p className="e-poppins-medium e-font-14 color-black">{item.created}</p>
                                                                </div>
                                                                <div className="col border-right-1px pe-4">
                                                                    <label className="e-poppins-medium e-font-14 color-outer-space">Mandate reg. on</label>
                                                                    <p className="e-poppins-medium e-font-14 color-black">{item.mandate_id}</p>
                                                                </div>
                                                                <div className="col border-right-1px pe-4">
                                                                    <label className="e-poppins-medium e-font-14 color-outer-space">Name</label>
                                                                    <p className="e-poppins-medium e-font-14 color-black">{item.account_holder_name}</p>
                                                                </div>
                                                                <div className="col border-right-1px pe-4">
                                                                    <label className="e-poppins-medium e-font-14 color-outer-space">Bank</label>
                                                                    <p className="e-poppins-medium e-font-14 color-black">{item.bank_name}</p>
                                                                </div>
                                                                <div className="col border-right-1px pe-4">
                                                                    <label className="e-poppins-medium e-font-14 color-outer-space">Account number</label>
                                                                    <p className="e-poppins-medium e-font-14 color-black">{item.account_number}</p>
                                                                </div>
                                                                <div className="col border-right-1px pe-4">
                                                                    <label className="e-poppins-medium e-font-14 color-outer-space">Remarks</label>
                                                                    <p className="e-poppins-medium e-font-14 color-black">{item.remarks ? item.remarks : '-'}</p>
                                                                </div>
                                                                <div className="col pe-4">
                                                                    <label className={`e-poppins-medium e-font-14 color-outer-space`}>Status</label>
                                                                    <p className={`e-poppins-medium e-font-14 ${item.status_str === 'SUCCESS' ? 'color-sea-green' : item.status_str === 'FAILED' ? 'color-red' : 'color-yellow-orange'}`}>{item.status_str}</p>
                                                                </div>
                                                            </div>
                                                            <div className="row">
                                                                <div className="col-6">
                                                                    {
                                                                        item.is_uploaded === false &&
                                                                        <div className="d-flex gap-8px bg-mint-cream p-2 border-radius-8px mt-3">
                                                                            <div className="w-max-content">
                                                                                <Icon icon="info"
                                                                                    size={24} />
                                                                            </div>
                                                                            <p className="e-font-16 e-poppins-regular line-height-24px color-jett-black mb-0 ">
                                                                                {`You can upload your signed document before ${item.start_date}`}
                                                                            </p>
                                                                        </div>
                                                                    }

                                                                </div>
                                                                <div className="col-6 text-end mt-2">
                                                                    <div className="d-flex justify-content-end mt-3">
                                                                        <button className={`${item.is_uploaded === false ? ' border-primary-color cursor-pointer color-primary-color' : 'color-old-silver border-old-silver pointer-events-none'} bg-white border-all border-radius-8px  padding-8px-tb  padding-12px-lr e-poppins-medium e-font-14  d-flex align-items-center`}
                                                                            onClick={() => _handleMandateUploadButton(item.mandate_id)}>
                                                                            <Icon icon="cloud-upload"
                                                                                color={item.is_uploaded === false ? Colors.primary : Colors.old_silver}
                                                                                size={18}
                                                                                className="me-1" />
                                                                            Upload
                                                                        </button>
                                                                        <OutlineButton label="Re-sent form"
                                                                            onPress={() => _handleReSentButton(item.mandate_id, item.download_file_url)}
                                                                            className="ms-3" />

                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        {
                                                            item.is_uploaded === true && item.status === false &&
                                                            <div className="d-flex gap-8px bg-pale-sky-blue border-radius-32px p-4 mt-3">
                                                                <div className="w-max-content">
                                                                    <Icon icon="info"
                                                                        size={30} />
                                                                </div>
                                                                <div >
                                                                    <h6 className="e-poppins-medium e-font-16 color-black">Your physical mandate is under review</h6>
                                                                    <p className="e-font-16 e-poppins-regular line-height-24px color-outer-space mb-0 ">In case of fund transfer via Net Banking, payment confirmation may take some time due to Clearing Corporation fund received. Fund transfer via UPI is instant</p>
                                                                </div>
                                                            </div>
                                                        }

                                                    </div>
                                                )
                                            })
                                        }
                                        <div className="col-12 mt-3">
                                            <GradientButton label="Create new mandate"
                                                className={`${mandateList.every(item => item.is_uploaded === true && item.status === true) ? 'bg-prime-gradient color-white' : 'color-old-silver bg-chinese-white pointer-events-none'} padding-8px-tb padding-16px-lr `}
                                                onPress={_showMandateModal}
                                            />
                                        </div>
                                    </div>
                            }

                        </>
                        :
                        <EmptyScreen title={props.userBank.length === 0 ? "User don't have linked any bank" : "Empty mandate data!"}
                            buttonLabel={props.userBank.length === 0 ? "" : "Create mandate"}
                            onPress={(_handleMandateButton)} />
            }
            {
                showMandateModal === true &&
                <CreateMandateModal userId={location.state.user_id}
                    bankData={props.userBank}
                    type={mandateType}
                    updateList={_getMandateList}
                    close={() => setShowMandateModal(false)} />
            }

            {
                openUploadDocModal === true && (
                    <UploadSignedMandate close={() => setOpenUploadDocModal(false)}
                        userId={location.state.user_id}
                        mandateId={selectedMandate}
                        updateMandateStatus={_updateMandateStatus} />
                )
            }
            {
                openShareModal === true && (
                    <SharePhysicalMandate close={() => {setOpenShareModal(false); setSelectedFormUrl("")}} 
                        url={selectedFormUrl}/>
                )
            }
        </>
    )
})

export default MandateList