/*
 *   File : admin-navigation.js
 *   Author URI : www.evoqins.com
 *   Description : Admin Navigation items
 *   Integrations : js-cookie
 *   Version : v1.0
 *   Created : 07-11-2023
 */
/*import package*/
import { useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";

/*import styles*/
import style from '../../Styles/Components/navigation-item.module.scss';

/*import components*/
import Icon from "../Icon/icon";
import Store from "../../Store";
import { CustomLoader } from "../Other";

import { _logout } from "../../Helper/api";

/*import store*/

const DASHBOARD_OPTIONS = [
    {
        name: "User summary",
        link: "/users",
        image_color: require("../../Assets/Images/Sidebar/users-color.png"),
        image_gray: require("../../Assets/Images/Sidebar/users-gray.png"),
    },
    {
        name: "Orders summary",
        link: "/orders",
        image_color: require("../../Assets/Images/Sidebar/orders-color.png"),
        image_gray: require("../../Assets/Images/Sidebar/orders-gray.png"),
    },
    {
        name: "Manage sub admins",
        link: "/sub-admins",
        image_color: require("../../Assets/Images/Sidebar/manage-subadmin-active.svg").default,
        image_gray: require("../../Assets/Images/Sidebar/manage-subadmin.svg").default,
    },
    {
        name: "Explore",
        link: "/explore",
        short_name: 'explore',
        image_color: require("../../Assets/Images/Sidebar/explore-color.png"),
        image_gray: require("../../Assets/Images/Sidebar/explore-gray.png"),
        show_always: true
    },
    {
        name: "Compare funds",
        link: "/compare-funds",
        short_name: 'explore',
        image_color: require("../../Assets/Images/Sidebar/compare-color.png"),
        image_gray: require("../../Assets/Images/Sidebar/compare-gray.png"),
        show_always: true
    },
    // {
    //     name: "Create notification",
    //     link: "/manage-notification",
    //     image_color: require("../../Assets/Images/Sidebar/create-notification-color.png"),
    //     image_gray: require("../../Assets/Images/Sidebar/create-notification-gray.png"),
    // },
    {
        name: "Login logs",
        link: "/admin-logs",
        image_color: require("../../Assets/Images/Sidebar/login-log-color.png"),
        image_gray: require("../../Assets/Images/Sidebar/login-log-gray.png"),
    },
    {
        name: "General settings",
        link: "/settings",
        image_color: require("../../Assets/Images/Sidebar/settings-color.png"),
        image_gray: require("../../Assets/Images/Sidebar/settings-gray.png"),
    },

    {
        name: "Manage content",
        link: "/content",
        image_color: require("../../Assets/Images/Sidebar/content-color.png"),
        image_gray: require("../../Assets/Images/Sidebar/content-gray.png"),
    },
    {
        name: "Manage fund",
        link: '/manage-allocation',
        image_color: require("../../Assets/Images/Sidebar/manage-fund-active.svg").default,
        image_gray: require("../../Assets/Images/Sidebar/manage-fund.svg").default,
    },
    {
        name: "KYC reasons",
        link: "/kyc-rejection-reasons",
        image_color: require("../../Assets/Images/Sidebar/settings-color.png"),
        image_gray: require("../../Assets/Images/Sidebar/settings-gray.png"),
    },

    {
        name: "Whitelist IP",
        link: "/whitelist-ip",
        image_color: require("../../Assets/Images/Sidebar/settings-color.png"),
        image_gray: require("../../Assets/Images/Sidebar/settings-gray.png"),
    },
    {
        name: "Manage Hierarchy",
        link: "/manage-hierarchy",
        short_name: 'manage-hierach',
        image_color: require("../../Assets/Images/Sidebar/manage-subadmin-active.svg").default,
        image_gray: require("../../Assets/Images/Sidebar/manage-subadmin.svg").default,
        show_always: true
    },


];

const AdminNavigation = (props) => {

    const location = useLocation();
    const navigator = useNavigate();
    const [show, setShow] = useState(false);
    const [showMenu, setShowMenu] = useState(false);
    const NAVIGATION = useSelector(state => state.MT_ADMIN_STORE.path);
    const SIDEBAR_TOGGLE = useSelector(state => state.MT_ADMIN_STORE.toggle);
    const [logoutLoader, setLogoutLoader] = useState(false);

    useEffect(() => {
        const handleNavigation = () => {
            // This function will be called on navigation changes
            const modal_element = document.querySelector('.modal-backdrop');
            if (modal_element) {
                modal_element.remove()
            }
        };

        // Add event listener for the 'popstate' event
        window.addEventListener('popstate', handleNavigation);

        // Clean up the event listener when the component is unmounted
        return () => {
            window.removeEventListener('popstate', handleNavigation);
        };
    }, []);

    useEffect(() => {
        setShow(SIDEBAR_TOGGLE);
    }, [SIDEBAR_TOGGLE]);


    useEffect(() => {
        Store.dispatch({
            type: 'UPDATE_NAVIGATION', payload: location.pathname
        })
        //eslint-disable-next-line
    }, [location.pathname]);

    // redirection
    const _handleNavigation = (link) => {
        setShowMenu(false)
        navigator(link);
        window.scroll({
            top: 0,
            behavior: 'smooth'
        });
    };

    // show and hide complete dashboard 
    const _handleSidebarToggle = () => {
        Store.dispatch({
            type: 'TOGGLE_SIDEBAR', payload: !show
        })
    }

    const _handleShowMenu = (menu_status) => {
        setShowMenu(menu_status);
    }

    function _handleLogout() {
        setLogoutLoader(true);
        _logout();
    }

    // prevent unwanted re render when redux update;
    const memoizedNavigationItems = useMemo(() => {
        // Matching the detail page
        const route_nodes = NAVIGATION.split("/");


        // if array length more than 2 means detail page taking the 1st node from url 
        const index_page = (route_nodes.length >= 2) ? `/${route_nodes[1]}` : NAVIGATION;
        return (
            DASHBOARD_OPTIONS.map((item, key) => {
                return (
                    <div key={key}
                        className={`e-font-16 d-flex align-items-center gap-8px py-2 padding-10px-lr margin-12px-bottom cursor-pointer white-space-nowrap 
                        ${item.link === index_page ?
                                `color-charleston-green e-poppins-semi-bold  border-left-2px ` :
                                `color-black e-poppins-regular ${style.e_navigation_items}`
                            }
                        `}
                        onClick={() => _handleNavigation(item.link)}>
                        <img draggable={false}
                            src={
                                item.link === index_page
                                    ? item.image_color
                                    : item.image_gray
                            }
                            width={24}
                            height={24}
                            alt={item.name} />

                        <p className="my-0">{item.name}</p>

                    </div>
                );
            })
        )
        //eslint-disable-next-line
    }, [DASHBOARD_OPTIONS, NAVIGATION]);

    const memoizedToggledNavigationItems = useMemo(() => {
        // Matching the detail page
        const route_nodes = NAVIGATION.split("/");

        // if array length more than 2 means detail page taking the 1st node from url 
        const index_page = (route_nodes.length >= 2) ? `/${route_nodes[1]}` : NAVIGATION;

        return (
            DASHBOARD_OPTIONS.map((item, key) => {
                return (
                    <div key={key}
                        className={`e-font-16 d-flex align-items-center justify-content-center gap-8px margin-12px-bottom cursor-pointer white-space-nowrap `}
                        onClick={() => {
                            _handleNavigation(item.link);
                        }}>
                        <span className={`${item.link === index_page ?
                            `color-charleston-green e-poppins-semi-bold py-2 padding-10px-lr  border-left-2px ` :
                            `color-black e-poppins-regular py-2 padding-10px-lr ${style.e_navigation_items}`
                            }
                        `}>
                            <img draggable={false}
                                src={
                                    item.link === index_page
                                        ? item.image_color
                                        : item.image_gray
                                }
                                width={24}
                                height={24}
                                alt={item.name}
                            />
                        </span>

                    </div>
                );
            })
        )
        //eslint-disable-next-line
    }, [DASHBOARD_OPTIONS, NAVIGATION]);

    return (
        <>
            {
                logoutLoader === true ? (
                    <div className="position-fixed w-100 h-100 top-0 left-0 z-index-10">
                        <CustomLoader />
                    </div>
                ) : (
                    <>
                        <div className="border-right-1px bg-pastel-blue position-fixed top-0  h-100 overflow-visible e-side-bar d-lg-block d-none ps-2 padding-10px-right" >
                            <div className="d-flex align-items-center justify-content-center pt-3 margin-20px-bottom e-sidebar-logo e-zoom-out-wrapper">

                                {
                                    show ?
                                        <img draggable={false}
                                            width={41}
                                            height={41}
                                            src={require("../../Assets/Images/Sidebar/toggle-mt-logo.svg").default}
                                            alt="Master Trust logo"
                                            className="mb-4 cursor-pointer"
                                            onClick={() => {
                                                navigator("/")
                                            }} />
                                        :
                                        <>
                                            <img draggable={false}
                                                src={require("../../Assets/Images/Sidebar/admin-type.svg").default}
                                                alt="Associates"
                                                className="position-absolute top-16px end-0" />
                                            <img draggable={false}
                                                src={require("../../Assets/Images/Sidebar/master-trust-logo.svg").default}
                                                alt="Master Trust logo"
                                                className="cursor-pointer"
                                                onClick={() => {
                                                    navigator("/")
                                                }} />
                                        </>
                                }

                            </div>



                            <div className={`${style.e_menu_item_wrapper} position-relative left-0 right-0 margin-auto e-zoom-out-wrapper e-sidebar-menu-wrapper ${show ? 'w-100' : ''}`}>


                                {
                                    show ?
                                        memoizedToggledNavigationItems
                                        :
                                        memoizedNavigationItems
                                }

                                {
                                    show ?
                                        <div className={`color-black e-poppins-regular e-font-16 d-flex align-items-center justify-content-center gap-8px py-2 padding-10px-lr cursor-pointer ${style.e_navigation_items}`}
                                            onClick={_handleLogout}>
                                            <img draggable={false}
                                                src={require('../../Assets/Images/Sidebar/logout-gray.png')}
                                                width={24}
                                                height={24}
                                                alt="Logout" />

                                        </div>
                                        :

                                        <div className={`color-black e-poppins-regular e-font-16 d-flex align-items-center gap-8px py-2 padding-10px-lr cursor-pointer ${style.e_navigation_items}`}
                                            onClick={_handleLogout}>
                                            <img draggable={false}
                                                src={require('../../Assets/Images/Sidebar/logout-gray.png')}
                                                width={24}
                                                height={24}
                                                alt="Logout" />
                                            <p className="my-0">
                                                Logout
                                            </p>
                                        </div>
                                }
                            </div>

                            {
                                show ?
                                    <Icon icon="toggle-inactive"
                                        width="24px"
                                        height="24px"
                                        className="e-toggle-icon-responsive "
                                        onClick={_handleSidebarToggle} />
                                    :
                                    <Icon icon="toggle-active"
                                        width="24px"
                                        height="24px"
                                        className="e-toggle-icon"
                                        onClick={_handleSidebarToggle} />
                            }


                        </div >
                        {/* mobile view */}
                        <div className='d-lg-none d-block'>
                            <div className='d-flex justify-content-between align-items-center ps-4 pt-3'>
                                <img draggable={false} src={require('../../Assets/Images/Sidebar/drawer.svg').default} alt="Menu"

                                    onClick={() => {
                                        _handleShowMenu(true);
                                    }} />
                            </div>

                        </div>

                        {
                            showMenu &&
                            <div className="border-right-1px bg-alice-blue h-150vh overflow-visible e-side-bar d-lg-none d-block px-4 w-100 position-absolute top-0 z-index-12" >
                                <div className="d-flex align-items-center justify-content-start pt-3 margin-20px-bottom e-sidebar-logo e-zoom-out-wrapper position-relative">


                                    <img draggable={false}
                                        src={require("../../Assets/Images/Sidebar/master-trust-logo.svg").default}
                                        alt="Master Trust logo" />

                                    <Icon icon="close"
                                        size={24}
                                        className="position-absolute e-close top-20px end-0 cursor-pointer"
                                        onClick={() => {
                                            _handleShowMenu(false)
                                        }} />


                                </div>



                                <div className={`${style.e_menu_item_wrapper} position-relative left-0 right-0  e-zoom-out-wrapper e-sidebar-menu-wrapper ${props.toggle ? 'w-100' : ''}`}>

                                    {/* navigation items */}

                                    {
                                        show ?
                                            memoizedToggledNavigationItems
                                            :
                                            memoizedNavigationItems
                                    }

                                    {
                                        show ?
                                            <div className={`color-black e-poppins-regular e-font-16 d-flex align-items-center justify-content-center gap-8px py-2 padding-10px-lr margin-58px-top cursor-pointer ${style.e_navigation_items}`}
                                            onClick={_handleLogout}>
                                                <img draggable={false}
                                                    src={require('../../Assets/Images/Sidebar/logout-gray.png')}
                                                    width={24}
                                                    height={24}
                                                    alt="Logout" />

                                            </div>
                                            :

                                            <div className={`color-black e-poppins-regular e-font-16 d-flex align-items-center gap-8px py-2 padding-10px-lr margin-58px-top cursor-pointer ${style.e_navigation_items}`}
                                            onClick={_handleLogout}>
                                                <img draggable={false}
                                                    src={require('../../Assets/Images/Sidebar/logout-gray.png')}
                                                    width={24}
                                                    height={24}
                                                    alt="Logout" />
                                                <p className="mb-0">
                                                    Logout
                                                </p>
                                            </div>
                                    }
                                </div>

                            </div >
                        }
                    </>
                )
            }
        </>
    )
}

export default AdminNavigation;