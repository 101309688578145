/*
 *   File : re-sent-mandate.js
 *   Author URI : https://evoqins.com
 *   Description : Share physical mandate form
 *   Integrations : null
 *   Version : v1.1
 */

import { useEffect } from "react";
import { toast } from "react-toastify";

import { Icon } from "../Icon";

import style from "../../Styles/Components/order-execution-success.module.scss"
import { GradientButton } from "../Cta";

const SharePhysicalMandate = (props) => {

    useEffect(() => {
        var my_modal = document.getElementById("share-physical-mandate");
        my_modal.addEventListener("hidden.bs.modal", () => {
            props.close();
        });
    }, [props.close]);

    // copy payment URL
    const _copyLink = () => {
        navigator.clipboard.writeText(props.url);
        toast.dismiss();
        toast.success('Linked copied', {
            type: 'success'
        });
    }

    // Open customer whatsapp chat
    const _openWhatsapp = () => {
        window.open('https://api.whatsapp.com/send?text=' + props.url, '_blank');
    }

    const _faceBookShare =() => {
        window.open('https://www.facebook.com/sharer/sharer.php?u=' + props.url, '_blank');
    }

    const _shareViaMail = () => {
        window.open('mailto:?subject=Physical mandate form &body=Kindly find the URL attached to this email to download the physical mandate form. ' + props.url, '_blank');
    }

    return (
        <div className={`modal fade ${style.e_send_mandate_modal} e-order-execution-sucess`}
            id="share-physical-mandate"
            tabIndex="-1"
            aria-labelledby="share-physical-mandate"
            aria-hidden="true"
            data-bs-backdrop="true" >
            <div className={`modal-dialog position-relative modal-dialog-centered ${style.e_dialog}`}>
                <div className={`modal-content  bg-white text-start padding-32px-all ${style.e_content} `}>
                    <h2 className="e-poppins-semi-bold e-font-18 color-eerie-black">Re-sent form via</h2>
                    <Icon icon="close"
                        size={24}
                        className={`cursor-pointer position-absolute ${style.e_close} e-modal-close-btn`}
                        data-bs-dismiss="modal" />
                    <p className="e-poppins-regular e-font-14 color-black mt-3">Share link via</p>
                    <div className="d-flex align-items-center justify-content-between gap-8px padding-12px cursor-pointer border-all  border-radius-8px e-copy-link-hover"
                        onClick={_copyLink}>
                        <div className="d-flex align-items-center pe-5">
                            <Icon icon="copy"
                                size={24} />
                            <p className="e-poppins-regular e-font-14 color-primary-color mb-0 ms-1 word-break-all">{props.url}</p>
                        </div>
                        <p className="color-outer-space e-poppins-regular e-font-14 mb-0 white-space-nowrap">Copy link</p>
                    </div>
                    <p className="e-poppins-regular e-font-14 color-black mt-3">Also share link via</p>
                    <div className="d-flex gap-16px">
                        <img draggable={false}
                            src={require('../../Assets/Images/Order-execution/Whatsapp.svg').default}
                            alt="whatsapp"
                            className="cursor-pointer"
                            onClick={_openWhatsapp}/>
                        {/* <img draggable={false}
                            src={require('../../Assets/Images/Order-execution/Telegram.svg').default}
                            alt="telegram"/>
                        <img draggable={false}
                            src={require('../../Assets/Images/Order-execution/Instagram.svg').default}
                            alt="instagram"/> */}
                        <img draggable={false}
                            src={require('../../Assets/Images/Order-execution/Facebook.svg').default}
                            alt="facebook"
                            className="cursor-pointer"
                            onClick={_faceBookShare}/>
                        {/* <img draggable={false}
                            src={require('../../Assets/Images/Order-execution/X.svg').default}
                            alt="x"/> */}
                        <img draggable={false}
                            src={require('../../Assets/Images/Order-execution/Gmail.svg').default}
                            alt="gmail"
                            className="cursor-pointer"
                            onClick={_shareViaMail}/>
                    </div>

                    {/* <GradientButton label="Sent to user"
                        className="mt-4"/> */}
                </div>
            </div>
        </div>
    )
}

export default SharePhysicalMandate