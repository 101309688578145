
/*
 *   File : create-rm.js
 *   Author URI : https://evoqins.com
 *   Description : Screen to create | update zone, rm, region, branch.
 *   Integrations : null
 *   Version : v1.1
 */

import { useEffect, useState } from "react";
import { toast } from "react-toastify";


import style from "../../Styles/Components/show-mandate.module.scss";

import { CustomTextInput, CustomSearchSelectBox } from "../FormElements";
import { GradientButton, PrimaryButton, SecondaryButton } from "../Cta";
import { CustomLoader } from "../Other";
import { Icon } from "../Icon";

import APIService from "../../Services/api-service";

export default function CreateRMModal(props) {

    const [name, setName] = useState("");
    const [code, setCode] = useState("");
    const [nameError, setNameError] = useState("");
    const [codeError, setCodeError] = useState("");
    const [regionList, setRegionList] = useState([]);
    const [branchList, setBranchList] = useState([]);
    const [selectedZone, setSelectedZone] = useState({});
    const [selectedZoneError, setSelectedZoneError] = useState("");
    const [selectedRegion, setSelectedRegion] = useState({});
    const [selectedRegionError, setSelectedRegionError] = useState("");
    const [selectedBranch, setSelectedBranch] = useState({});
    const [selectedBranchError, setSelectedBranchError] = useState("");
    const [apiLoader, setApiLoader] = useState(false);
    const [modalLoader, setModalLoader] = useState(false);
    useEffect(() => {
        var my_modal = document.getElementById("create-rm");
        my_modal.addEventListener("hidden.bs.modal", () => {
            props.close();
        });
    }, [props.close]);

    useEffect(() => {
        if (props.data !== null) {
            if (props.type > 2) {
                setModalLoader(true);
            }
            setName(props.data.name);
            setCode(props.data.code);
            if (props.type > 1) {
                setSelectedZone({ value: props.data.zone_id, label: props.data.zone_name });
            }
            if (props.type > 2) {
                setSelectedRegion({ value: props.data.region_id, label: props.data.region_name });
                _getRegionData(props.data.zone_id);
            }
            if (props.type > 3) {
                setSelectedBranch({ value: props.data.branch_id, label: props.data.branch_name });
                _getBranchList(props.data.region_id);
            }
        }
    }, [props.data]);

    function _handleNameInput(e) {
        setName(e);
        setNameError("");
    }

    function _handleCodeInput(e) {
        setCode(e);
        setCodeError("");
    }

    const _selectZone = (zone) => {
        _getRegionData(zone.value);
        setSelectedZone(zone);
        setSelectedRegion({});
        setSelectedBranch({});
        setSelectedZoneError("");
    }

    const _selectRegion = (region) => {
        _getBranchList(region.value);
        setSelectedRegion(region);
        setSelectedBranch({});
        setSelectedRegionError("");
    }

    const _selectBranch = (branch) => {
        setSelectedBranch(branch);
        setSelectedBranchError("");
    }

    function _formSubmit() {
        let valid = true;
        if (name === "") {
            setNameError("Name is required");
            valid = false;
        }
        if (code === "") {
            setCodeError("Code is required");
            valid = false;
        }
        if (props.type === 2 &&
            Object.keys(selectedRegion).length === 0 &&
            Object.keys(selectedZone).length === 0) {
            setSelectedZoneError("Please select zone")
            setSelectedRegionError("Please select region");
        }
        if (props.type === 3 &&
            Object.keys(selectedRegion).length === 0 &&
            Object.keys(selectedZone).length === 0 &&
            Object.keys(selectedBranch).length === 0) {
            setSelectedZoneError("Please select zone")
            setSelectedRegionError("Please select region");
            setSelectedBranchError("Please select branch");
        }
        if (props.type === 4 &&
            Object.keys(selectedRegion).length === 0 &&
            Object.keys(selectedZone).length === 0 &&
            Object.keys(selectedBranch).length === 0) {
            setSelectedZoneError("Please select zone")
            setSelectedRegionError("Please select region");
            setSelectedBranchError("Please select branch");
        }
        if (valid === true) {
            if (props.type === 1) {
                _createZone();
            } else if (props.type === 2) {
                _createRegion();
            }
            else if (props.type === 3) {
                _createBranch();
            }
            else {
                _createRM();
            }
            setApiLoader(true);
        }
    }

    // API - get region data based on zone
    function _getRegionData(id) {
        let url = 'client_mapping/region/list';
        let payload = JSON.stringify({
            id
        });
        APIService(true, url, payload).then((response) => {
            if (response.status_code === 200) {
                const modified_data = response.data.map((item) => {
                    return {
                        value: item.id,
                        label: item.name
                    }
                })
                setRegionList(modified_data);
            } else {
                setRegionList([])
            }
            if (props.type === 3) {
                setModalLoader(false);
            }
        })
    }

    function _getBranchList(id) {
        let url = 'client_mapping/branch/list';
        let payload = JSON.stringify({
            id
        });
        APIService(true, url, payload).then((response) => {
            if (response.status_code === 200) {
                const modified_data = response.data.map((item) => {
                    return {
                        value: item.id,
                        label: item.name
                    }
                })
                setBranchList(modified_data);
            } else {
                setBranchList([])
            }
            if (props.type === 4) {
                setModalLoader(false);
            }
        })
    }

    // API - create or update zone
    function _createZone() {
        let url = 'client_mapping/zone/create_update';
        let payload = JSON.stringify({
            id: props.data !== null ? props.data.id : null,
            name: name,
            code: code
        });
        APIService(true, url, payload).then((response) => {
            if (response.status_code === 200) {
                toast.dismiss();
                toast.success(`Zone ${props.data === null ? "created" : "updated"} successfully`)
                props.updateList(1);
                let close_btn = document.getElementById('close-mandate-modal');
                close_btn.click();
            } else {
                toast.dismiss();
                toast.error(response.message);
            }
            setApiLoader(false);
        })
    }

    // API - create or update region
    function _createRegion() {
        let url = 'client_mapping/region/create_update';
        let payload = JSON.stringify({
            id: props.data !== null ? props.data.id : null,
            name: name,
            code: code,
            zone_id: selectedZone?.value
        });
        APIService(true, url, payload).then((response) => {
            if (response.status_code === 200) {
                toast.dismiss();
                toast.success(`Region ${props.data === null ? "created" : "updated"} successfully`)
                props.updateList(2);
                let close_btn = document.getElementById('close-mandate-modal');
                close_btn.click();
            } else {
                toast.dismiss();
                toast.error(response.message);
            }
            setApiLoader(false);
        })
    }

    // API - create or update region
    function _createBranch() {
        let url = 'client_mapping/branch/create_update';
        let payload = JSON.stringify({
            id: props.data !== null ? props.data.id : null,
            name: name,
            code: code,
            region_id: selectedRegion?.value
        });
        APIService(true, url, payload).then((response) => {
            if (response.status_code === 200) {
                toast.dismiss();
                toast.success(`Branch ${props.data === null ? "created" : "updated"} successfully`)
                props.updateList(3);
                let close_btn = document.getElementById('close-mandate-modal');
                close_btn.click();
            } else {
                toast.dismiss();
                toast.error(response.message);
            }
            setApiLoader(false);
        })
    }

    // API - create or update region
    function _createRM() {
        let url = 'client_mapping/rm/create_update';
        let payload = JSON.stringify({
            id: props.data !== null ? props.data.id : null,
            name: name,
            code: code,
            branch_id: selectedBranch?.value
        });
        APIService(true, url, payload).then((response) => {
            if (response.status_code === 200) {
                toast.dismiss();
                toast.success(`RM ${props.data === null ? "created" : "updated"} successfully`)
                props.updateList(4);
                let close_btn = document.getElementById('close-mandate-modal');
                close_btn.click();
            } else {
                toast.dismiss();
                toast.error(response.message);
            }
            setApiLoader(false);
        })
    }



    return (
        <div className={`modal fade ${style.e_create_mandate}`}
            id="create-rm"
            tabIndex="-1"
            aria-labelledby="create-rm"
            aria-hidden="true"
            data-bs-backdrop="true" >
            <div className={`modal-dialog position-relative modal-dialog-centered ${style.e_dialog}`}>
                <div className={`modal-content  padding-32px-all ${style.e_content} `}>
                    <div className="d-flex align-items-center justify-content-between mb-4">
                        <p className="color-jett-black e-poppins-semi-bold e-font-18 mb-0">{props.heading}</p>
                        <Icon icon="close"
                            size={24}
                            data-bs-dismiss="modal"
                            className="cursor-pointer e-modal-close-btn" />
                        <button id="close-mandate-modal"
                            data-bs-dismiss="modal"
                            className="d-none"></button>
                    </div>
                    {
                        modalLoader === true ?
                            <div className="h-290px">
                                <CustomLoader />
                            </div>
                            :
                            <div className="row">
                                <div className="col-12 ">
                                    <CustomTextInput label="Name"
                                        postfix="*"
                                        personName={true}
                                        id={`name-input`}
                                        value={name}
                                        error={nameError}
                                        valueChange={_handleNameInput} />
                                </div>
                                <div className="col-12 mt-4 pt-2">
                                    <CustomTextInput label="Code"
                                        postfix="*"
                                        personName={true}
                                        id={`code-input`}
                                        value={code}
                                        error={codeError}
                                        valueChange={_handleCodeInput} />
                                </div>
                                {
                                    props.type !== 1 &&
                                    <div className="col-12 mt-4 pt-2">
                                        <CustomSearchSelectBox options={props.zoneList}
                                            value={Object.keys(selectedZone).length === 0 ? "" : selectedZone}
                                            label="Select zone"
                                            error={selectedZoneError}
                                            id="admin-zone"
                                            hide_error_text={false}
                                            placeholder=""
                                            postfix="*"
                                            onSelectChange={_selectZone} />
                                    </div>
                                }
                                {
                                    props.type > 2 && regionList.length > 0 &&
                                    <div className="col-12 mt-4 pt-2">
                                        <CustomSearchSelectBox options={regionList}
                                            value={Object.keys(selectedRegion).length === 0 ? "" : selectedRegion}
                                            label="Select Region"
                                            error={selectedRegionError}
                                            id="admin-zone"
                                            hide_error_text={false}
                                            placeholder=""
                                            postfix="*"
                                            onSelectChange={_selectRegion} />
                                    </div>
                                }
                                {
                                    props.type > 3 && branchList.length > 0 &&
                                    <div className="col-12 mt-4 pt-2">
                                        <CustomSearchSelectBox options={branchList}
                                            value={Object.keys(selectedBranch).length === 0 ? "" : selectedBranch}
                                            label="Select Branch"
                                            error={selectedBranchError}
                                            id="admin-zone"
                                            hide_error_text={false}
                                            placeholder=""
                                            postfix="*"
                                            onSelectChange={_selectBranch} />
                                    </div>
                                }

                                <div className="col-12 text-end mt-3">
                                    <SecondaryButton label="Cancel"
                                        cancel="modal"
                                        className="me-3" />
                                    <GradientButton label="Submit"
                                        loading={apiLoader}
                                        onPress={_formSubmit} />
                                </div>
                            </div>
                    }
                </div>
            </div>
        </div>
    )
}