/* import packages */

import { useEffect, useState } from 'react';
import { BrowserRouter as Router, Route, Routes, Outlet, Navigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import Cookies from 'js-cookie';

/* import components */
import { SideBar } from './Components/Header';
import { Footer } from './Components/Footer';
import { CustomLoader, Toast, PageNotFound } from './Components/Other';
import { AdminNavigation, SubAdminNavigation } from './Components/Navigation';

/* import containers */
// import { Dashboard } from './Containers/Dashboard';
// import { SuperAdminDashboard } from './Containers/Admin/Dashboard';
// import { UserDetail, UserListing } from './Containers/Users';
// import { FundOrderdetail, GoalOrderDetail, OrderListing } from './Containers/Orders';
import { Allocation } from './Containers/Funds';
import { FundSIPDetail, GoalSIPDetail, List } from './Containers/Sub-Admin/SIP';
import { NotificationListing } from './Containers/Notifications';
import { AddQuestion, QuizListing, QuizLogic } from './Containers/Risk';
import { ManageSubAdminContent } from './Containers/Sub-Admin/Content';
import { ManageSuperAdminContent } from './Containers/Admin/Content';
import { OtherSettings } from './Containers/Others';
import { ManageOnboarding, OnboardingDetail, KYCRequestList, CreateKYCRequest } from './Containers/KYC';
import { InitiateTransaction } from './Containers/Execution';
import { ManageNotification, Notifications } from './Containers/Admin/Notifications';
import { SubAdminDetail, SubAdminList, AddAdmin } from './Containers/Admin/Sub-Admin';
import { SubAdminDetailSubAdmin, SubAdminListSubAdmin, AddSubAdmin } from './Containers/Sub-Admin/Sub-Admin';
import { ManageSettings, AdminSettings } from './Containers/Admin/Settings';
import { SubAdminSettings } from './Containers/Sub-Admin/Settings';
import { Login, ForgotPassword, ConfigureAccount } from './Containers/Auth';
import { AdminUserDetail, AdminUserListing } from './Containers/Admin/User';
import { SubAdminUserDetail, SubAdminUserListing } from './Containers/Sub-Admin/User';
import { ProfileDetail } from './Containers/Admin/Profile';
import { SubAdminProfileDetail } from './Containers/Sub-Admin/Profile';
import { AdminFundOrderDetail, AdminGoalOrderDetail, Listing } from './Containers/Admin/Order';
import { SubAdminFundOrderDetail, SubAdminGoalOrderDetail, SubAdminOrderListing } from './Containers/Sub-Admin/Order';
import { ListingSTPSWP, STPDetail, SWPDetail } from './Containers/Sub-Admin/SWP-STP';
import { FundPortfolioDetail, GoalPortfolioDetail } from './Containers/Admin/User/helper';
import { AdminAllocation } from './Containers/Admin/Funds';
import { LoginLogs } from './Containers/Admin/Logs';
import { SubAdminLoginLogs } from './Containers/Sub-Admin/Logs';
import { IPListing } from './Containers/Admin/IP';
import { KYC } from './Containers/Create-Account';
import { ExploreFunds, FundDetail } from './Containers/Admin/Explore';
import { InvestInGoals, Retirement, House, Education, Car, Vacation, CustomGoal } from './Containers/Goals';
import CompareFunds from './Containers/Compare/compare-fund';
import HierachyLisitng from './Containers/Hierarchy/listing';
import { _getAdminProfileDetails } from './Helper/api';

import APIService from './Services/api-service';


/* import styles */
import './App.css';

import Store from './Store';

function App() {

    const MINIMIZED_SIDEBAR = useSelector(state => state.MT_ADMIN_STORE.toggle);
    const TOOLTIP_DATA = useSelector(state => state.MT_ADMIN_STORE.TOOL_TIP_DATA);

    const [isLoggedIn, setIsLoggedIn] = useState(Cookies.get("admin_session") ? true : false);
    const [pageLoader, setPageLoader] = useState(true);
    const [adminType, setAdminType] = useState(1);
    const [permissionData, setPermissionData] = useState([]);
    const [permissions, setPermissions] = useState([]);
    const [landingUrl, setLandingUrl] = useState(null);
    const [browserZoom, setBrowserZoom] = useState(100);

    useEffect(() => {
        window.addEventListener("resize", getSizes);

        return () => {
            window.removeEventListener("resize", getSizes);
        };
    }, []);

    useEffect(() => {
        if (Cookies.get('admin_session')) {
            _getProfile();
            setIsLoggedIn(true);
        } else {
            setIsLoggedIn(false);
        }
    }, [Cookies]);

    useEffect(()=>{
        async function _getToolTipData() {
            let url = 'general/get-tooltip';
            let response = await APIService(false, url).then((response) => {
                if (response.status_code === 200) {
                    Store.dispatch({
                        type: 'TOOLTIP_DATA',
                        payload: response.data
                    });
                }
            });
        }
        if (isLoggedIn === true && Object.keys(TOOLTIP_DATA).length === 0) {
            _getToolTipData();
        }
    },[isLoggedIn]);

    useEffect(() => {
        if (pageLoader === false) {
            _getLandingScreen();
        }
    }, [pageLoader]);

    const getSizes = () => {
        const new_zoom = ((window.outerWidth - 10) / window.innerWidth) * 100;
        const scale = window.devicePixelRatio;
        if (scale >= 1.5) {
            setBrowserZoom(Math.ceil(new_zoom));
        }
    };

    // API - get profile 
    const _getProfile = async () => {
        await _getAdminProfileDetails().then((response) => {
            Store.dispatch({
                type: 'GET_ADMIN_PROFILE',
                payload: response
            });
            setAdminType(response.admin_type_id);
            let permission_data = []
            response.permission_details.map((item) => {
                return (
                    permission_data.push(item.short_name)
                )
            })
            setPermissionData(permission_data);
            setPermissions(response.permission_details);
            setPageLoader(false);
        });
    }

    // landing url to be redirected
    const _getLandingScreen = () => {
        const permission_mapping = {};
        permissionData.forEach(permission => {
            permission_mapping[permission] = `/${permission.replace('_', '-')}`;
        });
        const first_key = Object.keys(permission_mapping)[0];
        const first_value = permission_mapping[first_key];
        setLandingUrl(first_value);

    }


    const SubAdminOrderRouter = () => {
        return (
            <Outlet />
        )
    }

    const UserRouter = () => {
        return (
            <Outlet />
        )
    }

    const SubAdminUserRouter = () => {
        return (
            <Outlet />
        )
    }

    const SuperAdminOrderRouter = () => {
        return (
            <Outlet />
        )
    }
    const QuizRouter = () => {
        return (
            <Outlet />
        )
    }

    const SIPRouter = () => {
        return (
            <Outlet />
        )
    }

    const STPRouter = () => {
        return (
            <Outlet />
        )
    }

    const OnboardingRouter = () => {
        return (
            <Outlet />
        )

    }

    const OrderExecutionRouter = () => {
        return (
            <div>
                <Outlet />
            </div>

        )
    }

    const SubAdminRouter = () => {
        return (
            <Outlet />
        )
    }

    const SubAdminSubRouter = () => {
        return (
            <Outlet />
        )
    }

    const LoginLogsRouter = () => {
        return (
            <Outlet />
        )
    }

    const KYCRequestRouter = () => {
        return (
            <Outlet />
        )
    }

    const GoalRouter = () => {
        return (
            <Outlet />
        )
    }

    const ExploreRouter = () => {
        return(
            <Outlet/>
        )
    }

    return (
        <Router>
            {
                isLoggedIn ?
                    <div className={`container-fluid ${browserZoom === 110 ? 'e-app-zoom-level-9' : browserZoom === 125 ? 'e-app-zoom-level-8' : browserZoom >= 150 ? 'e-app-zoom-level-7' : null}`}>
                        {
                            pageLoader === true ?
                                <div className="row h-100vh">
                                    <CustomLoader />
                                </div>
                                :

                                <div className="row" >
                                    <div className={MINIMIZED_SIDEBAR ? "e-navigation-hide px-0 position-relative w-92px" : "col-xl-2 col-lg-2 col-md-2  e-navigation-hide px-0 position-relative"}>
                                        {
                                            adminType === 1 ?
                                                <AdminNavigation />
                                                :
                                                <SubAdminNavigation />

                                        }
                                    </div>

                                    <div className={MINIMIZED_SIDEBAR ? "col-xl-11 col-lg-11 px-0 e-zoom-out-wrapper" : "col-xl-10 col-lg-10 col-md-10 px-0 e-zoom-out-wrapper"}>
                                        <div className='e-body-content d-flex flex-direction-column'>

                                            <Routes>
                                                {/* Super admin routes */}
                                                {
                                                    adminType === 1 ?
                                                        <>
                                                            <Route path="/" element={<Navigate to="/users" />} />

                                                            <Route path="/users" element={<UserRouter />}>
                                                                <Route index element={<AdminUserListing />} />
                                                                <Route path="customer-detail" element={<AdminUserDetail />} />
                                                                <Route path="new" element={<AddAdmin />} />
                                                            </Route>

                                                            <Route path="/orders" element={<SuperAdminOrderRouter />}>
                                                                <Route index element={<Listing />} />
                                                                <Route path="fund-order-detail" element={<AdminFundOrderDetail />} />
                                                                <Route path="goal-order-detail" element={<AdminGoalOrderDetail />} />
                                                                <Route path="mutual-fund-detail" element={<FundPortfolioDetail />} />
                                                                <Route path="goal-fund-detail" element={<GoalPortfolioDetail />} />
                                                            </Route>

                                                            <Route path="/sub-admins" element={<SubAdminRouter />}>
                                                                <Route index element={<SubAdminList />} />
                                                                <Route path="detail" element={<SubAdminDetail />} />
                                                                <Route path="new" element={<AddAdmin />} />
                                                            </Route>

                                                            <Route path="/admin-logs" element={<LoginLogsRouter />}>
                                                                <Route index element={<LoginLogs />} />
                                                            </Route>

                                                            <Route path="/whitelist-ip" element={<IPListing />}>

                                                            </Route>

                                                            <Route path="/manage-allocation" element={<AdminAllocation />}>

                                                            </Route>

                                                            <Route path="/content"
                                                                element={<ManageSuperAdminContent />} >
                                                            </Route>

                                                            <Route path="/kyc-rejection-reasons"
                                                                element={<AdminSettings />} >
                                                            </Route>

                                                            <Route path="/notifications"
                                                                element={<Notifications />} >
                                                            </Route>

                                                            <Route path="/manage-notification"
                                                                element={<ManageNotification />} >
                                                            </Route>

                                                            <Route path="/explore" element={<ExploreRouter />} >
                                                                <Route index element={<ExploreFunds />} />
                                                                <Route path="fund" element={<FundDetail />} />
                                                            </Route>

                                                            <Route path="/settings"
                                                                element={<ManageSettings />} >
                                                            </Route>

                                                            <Route path="/notifications"
                                                                element={<Notifications />} >
                                                            </Route>

                                                            <Route path="/create-account"
                                                                element={<KYC />}>
                                                            </Route>

                                                            <Route path="/profile"
                                                                element={<ProfileDetail />} >
                                                            </Route>

                                                            <Route path="/compare-funds" 
                                                                element={<CompareFunds />} >
                                                            </Route>

                                                            <Route path="/manage-hierarchy" 
                                                                element={<HierachyLisitng />} >
                                                            </Route>
                                                        </>
                                                        :
                                                        // Sub admin routes
                                                        <>
                                                            <Route path="/" element={landingUrl === null ? <CustomLoader /> : <Navigate to={landingUrl} />} />

                                                            <Route path="/user-summary" element={<SubAdminUserRouter />}>
                                                                <Route index element={<SubAdminUserListing />} />
                                                                {
                                                                    permissionData.includes("user_summary") === true &&
                                                                    <Route path="customer-detail" element={<SubAdminUserDetail />} />
                                                                }

                                                            </Route>
                                                            {
                                                                permissionData.includes("order_book") === true &&
                                                                <Route path="/order-book" element={<SubAdminOrderRouter />}>
                                                                    <Route index element={<SubAdminOrderListing />} />
                                                                </Route>
                                                            }

                                                            {
                                                                (permissionData.includes("order_book") === true ||
                                                                    permissionData.includes("sip_manage") === true) &&
                                                                <>
                                                                    <Route path="/order-book/fund-order-detail" element={<SubAdminFundOrderDetail />} />
                                                                    <Route path="/order-book/goal-order-detail" element={<SubAdminGoalOrderDetail />} />
                                                                    <Route path="/order-book/mutual-fund-detail" element={<FundPortfolioDetail />} />
                                                                    <Route path="/order-book/goal-fund-detail" element={<GoalPortfolioDetail />} />
                                                                </>
                                                            }


                                                            {
                                                                permissionData.includes("sip_manage") === true &&
                                                                <Route path="/sip-manage" element={<SIPRouter />}>
                                                                    <Route index element={<List />} />
                                                                    <Route path='sip-summary' element={<FundSIPDetail />}></Route>
                                                                    <Route path='sip-goal-summary' element={<GoalSIPDetail />}></Route>
                                                                </Route>
                                                            }

                                                            {
                                                                <Route path="/manage-stp-swp" element={<STPRouter />}>
                                                                    <Route index element={<ListingSTPSWP />} />
                                                                    <Route path="stp-summary" element={<STPDetail />} />
                                                                    <Route path="swp-summary" element={<SWPDetail />} />
                                                                </Route>
                                                            }

                                                            {
                                                                permissionData.includes("login_logs") === true &&
                                                                <Route path="/login-logs" element={<SubAdminLoginLogs />} />
                                                            }

                                                            {
                                                                permissionData.includes("manage_content") === true &&
                                                                <Route path="/manage-content"
                                                                    element={<ManageSubAdminContent />} >
                                                                </Route>
                                                            }
                                                            {
                                                                permissionData.includes("admin_settings") === true &&
                                                                <Route path="/kyc-rejection-reasons"
                                                                    element={<SubAdminSettings />} >
                                                                </Route>
                                                            }

                                                            {
                                                                permissionData.includes("manage_onboarding") === true &&
                                                                <Route path="/manage-onboarding" element={<OnboardingRouter />}>
                                                                    <Route index element={<ManageOnboarding />} />
                                                                    <Route path='detail' element={<OnboardingDetail />} />
                                                                </Route>
                                                            }

                                                            {/* {
                                                                permissionData.includes("place_order") === true && */}


                                                            <Route path="/place-order" element={<OrderExecutionRouter />}>
                                                                <Route index element={<InitiateTransaction />} />
                                                                <Route path="goal" element={<GoalRouter />}>
                                                                    <Route index element={<InvestInGoals />} />
                                                                    <Route path="retirement" element={<Retirement />} />
                                                                    <Route path="house" element={<House />} />
                                                                    <Route path="education" element={<Education />} />
                                                                    <Route path="car" element={<Car />} />
                                                                    <Route path="vacation" element={<Vacation />} />
                                                                    <Route path="custom-goal" element={<CustomGoal />} />
                                                                </Route>
                                                            </Route>
                                                            {/* } */}

                                                            {
                                                                permissionData.includes("manage_fund") === true &&
                                                                <Route path="/manage-fund"
                                                                    element={<Allocation />} >
                                                                </Route>
                                                            }

                                                            {
                                                                permissionData.includes("manage_sub_admin") === true &&
                                                                <Route path="/manage-sub-admin" element={<SubAdminSubRouter />}>
                                                                    <Route index element={<SubAdminListSubAdmin />} />
                                                                    {
                                                                        permissions.filter(item => item.short_name === "manage_sub_admin")[0].action === "write" &&
                                                                        <>
                                                                            <Route path="detail" element={<SubAdminDetailSubAdmin />} />
                                                                            <Route path="new" element={<AddSubAdmin />} />
                                                                        </>
                                                                    }
                                                                </Route>
                                                            }

                                                            {
                                                                permissionData.includes("notification_maker") === true &&
                                                                <Route path="/push-notifications"
                                                                    element={<NotificationListing />} >
                                                                </Route>
                                                            }

                                                            {
                                                                permissionData.includes("risk_planning_questionnaire") === true &&
                                                                <Route path="/risk-planning-questionnaire" element={<QuizRouter />}>
                                                                    <Route index element={<QuizListing />} />
                                                                    <Route path="how-it-works" element={<QuizLogic />} />
                                                                    {
                                                                        permissions.filter(item => item.short_name === "risk_planning_questionnaire")[0].action === "write" &&
                                                                        <>
                                                                            <Route path="add" element={<AddQuestion />} />
                                                                            <Route path="edit" element={<AddQuestion />} />
                                                                        </>
                                                                    }

                                                                </Route>
                                                            }

                                                            {
                                                                permissionData.includes("general_settings") === true &&
                                                                <Route path="/general-settings"
                                                                    element={<OtherSettings />} >
                                                                </Route>
                                                            }

                                                            <Route path="/profile"
                                                                element={<SubAdminProfileDetail />} >
                                                            </Route>
                                                            
                                                            <Route path="/compare-funds" 
                                                                element={<CompareFunds />} >
                                                            </Route>

                                                            <Route path="/manage-hierarchy" 
                                                                element={<HierachyLisitng />} >
                                                            </Route>

                                                            <Route path="/kyc-request" element={<KYCRequestRouter />}>
                                                                <Route index element={<KYCRequestList />}></Route>
                                                                <Route path="create-request" element={<CreateKYCRequest />}>
                                                                </Route>
                                                            </Route>

                                                            <Route path="/create-account"
                                                                element={<KYC />}>
                                                            </Route>

                                                            <Route path="/notifications"
                                                                element={<Notifications />} >
                                                            </Route>
                                                            <Route path="/explore" element={<ExploreRouter />} >
                                                                <Route index element={<ExploreFunds />} />
                                                                <Route path="fund" element={<FundDetail />} />
                                                            </Route>

                                                        </>
                                                }
                                                <Route path="*" element={<PageNotFound />} />
                                            </Routes>
                                        </div>

                                        <div className='mt-auto'>
                                            <Footer />
                                        </div>
                                    </div>
                                </div>
                        }

                    </div>
                    :
                    <div className='e-body-content pt-0'>
                        <Routes>
                            <Route index path="/"
                                element={<Login />} >
                            </Route>
                            <Route path='forgot-password'
                                element={<ForgotPassword />}>
                            </Route>
                            <Route path='configure-account'
                                element={<ConfigureAccount />}>
                            </Route>
                        </Routes>
                    </div>
            }
            <Toast />
        </Router >
    );
}

export default App;

