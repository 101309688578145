

/*
 *   File : listing.js
 *   Author URI : https://evoqins.com
 *   Description : Listing page for 
 *   Integrations : null
 *   Version : v1.1
 */
// import packages
import { useEffect, useState, useRef } from "react";
import { Modal } from "bootstrap";
// import components
import { NavigationHeader } from "../../Components/Header";
import { CustomLoader, EmptyScreen } from "../../Components/Other";
import { CustomTab } from "../../Components/Tab";
import { DataTableContainer, CustomSearchSelectBox } from "../../Components/FormElements";
import { Icon } from "../../Components/Icon";
import { CreateRMModal, RemoveRMModal } from "../../Components/Modal";
import { GradientButton } from "../../Components/Cta";

import Colors from '../../Styles/color.module.scss';
// import services
import APIService from "../../Services/api-service";
import { select } from "redux-saga/effects";

const PAGE_NAVIGATION = [
    'Home',
    'Manage Hierarchy'
];

const TAB_DATA = [
    "Zone Management",
    "Region Management",
    "Branch Management",
    "RM Management"
]

export default function HierachyLisitng(props) {

    const [tabIndex, setTabIndex] = useState(1);
    const [apiLoader, setApiLoader] = useState(true);
    const [tableData, setTableData] = useState([]);
    const [showDeletedRed, setShowDeletedRed] = useState(-1);
    const [openCreateModal, setOpenCreaetModal] = useState(false);
    const [selectedData, setSelectedData] = useState(null);
    const [modalTitle, setModalTitle] = useState("");
    const [zoneList, setZoneList] = useState([]);
    const [regionList, setRegionList] = useState([]);
    const [branchList, setBranchList] = useState([]);
    const [openDeleteModal, setOpenDeleteModal] = useState(false);
    const [selectedId, setSelectedId] = useState(null);
    const [remapAvailable, setRemapAvailable] = useState(false);
    const [selectedZone, setSelectedZone] = useState({});
    const [selectedRegion, setSelectedRegion] = useState({});
    const [selectedBranch, setSelectedBranch] = useState({});

    const TABLE_STYLE = {
        table: {
            style: {
                padding: "12px 16px 16px 16px",
                background: Colors.white,
            },
        },
        tableBody: {
            style: {
                margin: '0px',
                marginTop: "0px !important"
            }
        },
        headRow: {
            style: {
                background: 'transparent',
                borderRadius: "16px 16px 0px 0px",
                border: "0",
                borderBottom: `1px solid ${Colors.bright_gray} !important`,
                fontSize: "14px",
                fontFamily: "Poppins-Medium",
                lineHeight: "24px",
                color: Colors.outer_space
            }
        },
        rows: {
            style: {
                margin: "0",
                background: "transparent",
                border: `1px solid ${Colors.bright_gray} !important`,
                borderRadius: "16px",
                marginTop: "14px",
                fontSize: "16px",
                fontFamily: "Poppins-Regular",
                lineHeight: "24px",
            },
        },
        cells: {
            style: {
                '&:not(:last-child)': {
                    paddingLeft: '0px',
                    paddingRight: '0px',
                    display: 'flex',
                },
                '&:first-child': {
                    padding: "24px 0px 24px 24px",
                }
            },
        },
        headCells: {
            style: {
                '&:not(:last-child)': {
                    paddingLeft: '0px',
                    paddingRight: '0px',
                    display: 'flex',
                },
                '&:first-child': {
                    padding: "24px 0px 24px 24px",
                }
            },
        },
        pagination: {
            style: {
                borderTop: `none `,
                color: Colors.outer_space,
                fontSize: '16px',
                fontFamily: "Poppins-Regular",
            },
        }
    }

    const ZONE_COLUMNS = [

        {
            name: <div className={`position-relative w-fit-content `}>


                <p className="mb-0">Name</p>
            </div>,
            selector: row => row['name'],
            sortable: true,
            left: true,
            width: '300px',
            cell: row =>
                <div className="d-flex align-items-center gap-12px e-fund-name ps-2" >

                    <p className="color-primary-color e-poppins-regular e-font-16 line-height-24px mb-0 bg-white mt-0">{row['name']}</p>
                </div>
        },
        {
            name: 'Code',
            selector: row => row['code'],
            sortable: false,
            left: true,
            width: '300px',
            cell: row => <span className="color-outer-space"> {row['code']}</span>,
        },
        {
            name: '',
            sortable: false,
            center: true,
            width: "300px",
            cell: (row, key) =>
                <div className="d-flex">
                    <p className='color-primary-color line-height-16px e-poppins-regular e-font-16 e-tool-tip-container position-relative h-fit-content  my-0 cursor-pointer'
                        data-tooltip-content="Edit selected"
                        placement="bottom"
                        onClick={() => _handleEdit(row)}>
                        Edit
                        <Icon icon="Pen-square"
                            size={24}
                            className="ms-1" />
                    </p>
                    <p className={`${showDeletedRed === key ? 'color-red' : 'color-primary-color'} line-height-16px e-poppins-regular e-font-16  my-0 ps-3 e-tool-tip-container position-relative h-fit-content`}
                        data-tooltip-content="Delete selected"
                        placement="bottom"
                        onMouseEnter={() => {
                            setShowDeletedRed(key);
                        }}
                        onMouseLeave={() => {
                            setShowDeletedRed(-1);
                        }}
                        onClick={() => _handleDeleteClick(row)}>
                        Remove


                        <Icon icon={showDeletedRed === key ? "delete-red" : "delete"}
                            size={24}
                        />

                    </p>
                </div>
        },

    ];

    const REGION_COLUMN = [

        {
            name: <div className={`position-relative w-fit-content `}>


                <p className="mb-0">Name</p>
            </div>,
            selector: row => row['name'],
            sortable: true,
            left: true,
            width: '300px',
            cell: row =>
                <div className="d-flex align-items-center gap-12px e-fund-name ps-2" >

                    <p className="color-primary-color e-poppins-regular e-font-16 line-height-24px mb-0 bg-white mt-0">{row['name']}</p>
                </div>
        },
        {
            name: 'Code',
            selector: row => row['code'],
            sortable: false,
            left: true,
            width: '300px',
            cell: row => <span className="color-outer-space"> {row['code']}</span>,
        },
        {
            name: '',
            sortable: false,
            center: true,
            width: "300px",
            cell: (row, key) =>
                <div className="d-flex">
                    <p className='color-primary-color line-height-16px e-poppins-regular e-font-16 e-tool-tip-container position-relative h-fit-content  my-0 cursor-pointer'
                        data-tooltip-content="Edit selected"
                        placement="bottom"
                        onClick={() => _handleEdit(row)}>
                        Edit
                        <Icon icon="Pen-square"
                            size={24}
                            className="ms-1" />
                    </p>
                    <p className={`${showDeletedRed === key ? 'color-red' : 'color-primary-color'} line-height-16px e-poppins-regular e-font-16  my-0 ps-3 e-tool-tip-container position-relative h-fit-content`}
                        data-tooltip-content="Delete selected"
                        placement="bottom"
                        onMouseEnter={() => {
                            setShowDeletedRed(key);
                        }}
                        onMouseLeave={() => {
                            setShowDeletedRed(-1);
                        }}
                        onClick={() => _handleDeleteClick(row)}>
                        Remove


                        <Icon icon={showDeletedRed === key ? "delete-red" : "delete"}
                            size={24}
                        />

                    </p>
                </div>
        },

    ];

    const BRNACH_COLUMN = [

        {
            name: <div className={`position-relative w-fit-content `}>


                <p className="mb-0">Name</p>
            </div>,
            selector: row => row['name'],
            sortable: true,
            left: true,
            width: '300px',
            cell: row =>
                <div className="d-flex align-items-center gap-12px e-fund-name ps-2" >

                    <p className="color-primary-color e-poppins-regular e-font-16 line-height-24px mb-0 bg-white mt-0">{row['name']}</p>
                </div>
        },
        {
            name: 'Code',
            selector: row => row['code'],
            sortable: false,
            left: true,
            width: '300px',
            cell: row => <span className="color-outer-space"> {row['code']}</span>,
        },
        {
            name: '',
            sortable: false,
            center: true,
            width: "300px",
            cell: (row, key) =>
                <div className="d-flex">
                    <p className='color-primary-color line-height-16px e-poppins-regular e-font-16 e-tool-tip-container position-relative h-fit-content  my-0 cursor-pointer'
                        data-tooltip-content="Edit selected"
                        placement="bottom"
                        onClick={() => _handleEdit(row)}>
                        Edit
                        <Icon icon="Pen-square"
                            size={24}
                            className="ms-1" />
                    </p>
                    <p className={`${showDeletedRed === key ? 'color-red' : 'color-primary-color'} line-height-16px e-poppins-regular e-font-16  my-0 ps-3 e-tool-tip-container position-relative h-fit-content`}
                        data-tooltip-content="Delete selected"
                        placement="bottom"
                        onMouseEnter={() => {
                            setShowDeletedRed(key);
                        }}
                        onMouseLeave={() => {
                            setShowDeletedRed(-1);
                        }}
                        onClick={() => _handleDeleteClick(row)}>
                        Remove


                        <Icon icon={showDeletedRed === key ? "delete-red" : "delete"}
                            size={24}
                        />

                    </p>
                </div>
        },

    ];

    const RM_COLUMN = [

        {
            name: <div className={`position-relative w-fit-content `}>


                <p className="mb-0">Name</p>
            </div>,
            selector: row => row['name'],
            sortable: true,
            left: true,
            width: '300px',
            cell: row =>
                <div className="d-flex align-items-center gap-12px e-fund-name ps-2" >

                    <p className="color-primary-color e-poppins-regular e-font-16 line-height-24px mb-0 bg-white mt-0">{row['name']}</p>
                </div>
        },
        {
            name: 'Code',
            selector: row => row['code'],
            sortable: false,
            left: true,
            width: '300px',
            cell: row => <span className="color-outer-space"> {row['code']}</span>,
        },
        {
            name: '',
            sortable: false,
            center: true,
            width: "300px",
            cell: (row, key) =>
                <div className="d-flex">
                    <p className='color-primary-color line-height-16px e-poppins-regular e-font-16 e-tool-tip-container position-relative h-fit-content  my-0 cursor-pointer'
                        data-tooltip-content="Edit selected"
                        placement="bottom"
                        onClick={() => _handleEdit(row)}>
                        Edit
                        <Icon icon="Pen-square"
                            size={24}
                            className="ms-1" />
                    </p>
                    <p className={`${showDeletedRed === key ? 'color-red' : 'color-primary-color'} line-height-16px e-poppins-regular e-font-16  my-0 ps-3 e-tool-tip-container position-relative h-fit-content`}
                        data-tooltip-content="Delete selected"
                        placement="bottom"
                        onMouseEnter={() => {
                            setShowDeletedRed(key);
                        }}
                        onMouseLeave={() => {
                            setShowDeletedRed(-1);
                        }}
                        onClick={() => _handleDeleteClick(row)}>
                        Remove


                        <Icon icon={showDeletedRed === key ? "delete-red" : "delete"}
                            size={24}
                        />

                    </p>
                </div>
        },

    ];


    useEffect(() => {
        if (tabIndex === 1) {
            _getZone();
        } else if (tabIndex === 2) {
            _getRegionData();
        } else if (tabIndex === 3) {
            _getBranchData();
        } else {
            _getRmData();
        }
        setApiLoader(true);
    }, [tabIndex]);

    useEffect(() => {
        if (openCreateModal === true) {
            const modal = new Modal(document.getElementById("create-rm"));
            modal.show();
        }
    }, [openCreateModal]);

    useEffect(() => {
        if (openDeleteModal === true) {
            const modal = new Modal(document.getElementById("remove-rm"));
            modal.show();
        }
    }, [openDeleteModal]);

    //Handle tab change 
    function _handleTab(index) {
        setTabIndex(index);
        setOpenCreaetModal(false);
        setOpenDeleteModal(false);
        setSelectedZone({});
        setSelectedRegion({});
        setSelectedBranch({});
    }

    function _handleModalClose() {
        setOpenCreaetModal(false);
        setSelectedData(null);
    }

    function _handleEdit(row) {
        if (tabIndex === 1) {
            setModalTitle('Update zone');
        } else if (tabIndex === 2) {
            setModalTitle("Update Region");
        } else if (tabIndex === 3) {
            setModalTitle("Update branch");
        } else {
            setModalTitle("Update RM");
        }
        setSelectedData(row);
        setOpenCreaetModal(true);
    }

    function _handleDeleteClick(row) {
        setSelectedId(row.id);
        if (row.has_customer === true || row.has_admin === true) {
            setRemapAvailable(true);
        }
        setOpenDeleteModal(true);
    }

    function _openCreateModal() {
        let modal_title = ""
        if (tabIndex === 1) {
            modal_title = "Create new zone";
        } else if (tabIndex === 2) {
            modal_title = "Create new region";
        } else if (tabIndex === 3) {
            modal_title = "Create new branch";
        }
        else {
            modal_title = "Create new RM";
        }
        setModalTitle(modal_title);
        setOpenCreaetModal(true);
    }

    function _selectZone(value) {
        setSelectedZone(value);
        setSelectedRegion({});
        setSelectedBranch({});
        _getRegionData(value.value);
    }

    function _selectRegion(value) {
        setSelectedRegion(value);
        setSelectedBranch({});
        _getBranchData(value.value);
    }

    function _selectBranch(value) {
        setSelectedBranch(value);
        _getRmData(value.value);
    }

    function _reloadData(type) {
        setOpenDeleteModal(false);
        setSelectedId(null);
        _updateList(type);
    }

    function _updateList(type) {
        if (type === 1) {
            _getZone();
        } else if (type === 2) {
            _getRegionData(selectedZone?.value);
        } else if (type === 3) {
            _getBranchData(selectedRegion?.value);
        } else {
            _getRmData(selectedBranch?.value);
        }
    }

    // API - get zone data
    function _getZone() {
        if (tabIndex === 1) {
            setApiLoader(true);
        }
        let url = "client_mapping/zone/list";
        APIService(false, url).then((response) => {
            if (response.status_code === 200) {
                if (tabIndex === 1) {
                    setTableData(response.data);
                }
                const modified_data = response.data.map((item) => {
                    return {
                        value: item.id,
                        label: item.name
                    }
                })
                setZoneList(modified_data);
            } else {
                setTableData([]);
            }
            setApiLoader(false);
        })
    }

    // API - get RM data
    function _getRegionData(zone = null) {
        if (tabIndex === 2) {
            setApiLoader(true);
        }
        let url = 'client_mapping/region/list';
        let payload = JSON.stringify({
            id: zone
        });
        APIService(true, url, payload).then((response) => {
            if (response.status_code === 200) {
                if (tabIndex === 2) {
                    setTableData(response.data);
                }
                const modified_data = response.data.map((item) => {
                    return {
                        value: item.id,
                        label: item.name
                    }
                })
                setRegionList(modified_data);
            } else {
                setRegionList([]);
                if (tabIndex === 2) {
                    setTableData([]);
                }
            }
            setApiLoader(false);
        })
    }

    // API - get Branch data
    function _getBranchData(rm_id = null) {
        if (tabIndex === 3) {
            setApiLoader(true);
        }
        let url = 'client_mapping/branch/list';
        let payload = JSON.stringify({
            id: rm_id
        });
        APIService(true, url, payload).then((response) => {
            if (response.status_code === 200) {
                console.log('tabIndex', tabIndex);
                if (tabIndex === 3) {
                    setTableData(response.data);
                }
                const modified_data = response.data.map((item) => {
                    return {
                        value: item.id,
                        label: item.name
                    }
                })
                setBranchList(modified_data);
            } else {
                setBranchList([]);
                if (tabIndex === 3) {
                    setTableData([]);
                }
            }
            setApiLoader(false);
        })
    }

    // API - get RM data
    function _getRmData(branch_id = null) {
        if (tabIndex === 4) {
            setApiLoader(true);
        }
        let url = 'client_mapping/rm/list';
        let payload = JSON.stringify({
            id: branch_id
        });
        APIService(true, url, payload).then((response) => {
            if (response.status_code === 200) {
                if (tabIndex === 4) {
                    setTableData(response.data);
                }
            } else {
                if (tabIndex === 4) {
                    setTableData([]);
                }
            }
            setApiLoader(false);
        })
    }

    return (
        <>
            <NavigationHeader
                type={1}
                data={PAGE_NAVIGATION}
                title="Manage Hierarchy" />
            <div className=" e-manage-sip ps-4 pe-lg-5 pe-md-2 pe-2 padding-32px-top">
                <p className="color-black e-poppins-medium e-font-20-sm-18 line-height-32px my-sm-4 my-3">Manage Hierarchy</p>
                <div className="row">
                    <div className="col-9">
                        <CustomTab type={1}
                            index={tabIndex}
                            data={TAB_DATA}
                            onSelectTab={_handleTab} />
                    </div>
                    <div className="col-3 text-end">
                        <GradientButton label={`Create ${tabIndex === 1 ? "Zone" : tabIndex === 2 ? "Region" : tabIndex === 3 ? "Branch" : "RM"}`}
                            onPress={_openCreateModal} />
                    </div>
                </div>
                <div className="row mt-4">
                    <div className="col-12">
                        <div className="d-flex w-100 justify-content-end">
                            {
                                tabIndex > 1 &&
                                <CustomSearchSelectBox options={zoneList}
                                    value={Object.keys(selectedZone).length === 0 ? "" : selectedZone}
                                    label="Select zone"
                                    className="w-25"
                                    id="admin-zone"
                                    hide_error_text={false}
                                    placeholder=""
                                    onSelectChange={_selectZone} />
                            }

                            {
                                tabIndex > 2 &&
                                <CustomSearchSelectBox options={regionList}
                                    value={Object.keys(selectedRegion).length === 0 ? "" : selectedRegion}
                                    label="Select region"
                                    className="w-25 ms-3"
                                    id="admin-zone"
                                    hide_error_text={false}
                                    placeholder=""
                                    onSelectChange={_selectRegion} />
                            }

                            {
                                tabIndex > 3 &&
                                <CustomSearchSelectBox options={branchList}
                                    value={Object.keys(selectedBranch).length === 0 ? "" : selectedBranch}
                                    label="Select branch"
                                    className="w-25 ms-3"
                                    id="admin-zone"
                                    hide_error_text={false}
                                    placeholder=""
                                    onSelectChange={_selectBranch} />
                            }

                        </div>
                    </div>
                </div>
                <div className="row">

                    {
                        apiLoader === true ?
                            <div className="col-12 h-80vh">
                                <CustomLoader />
                            </div>
                            :
                            tableData.length !== 0 ?
                                <div className="min-height-80vh">
                                    <div className="col-12 position-relative" id="e-table-body">
                                        <DataTableContainer columns={tabIndex === 1 ? ZONE_COLUMNS : tabIndex === 2 ? REGION_COLUMN : tabIndex === 3 ? BRNACH_COLUMN : RM_COLUMN}
                                            data={tableData}
                                            selectableRows={false}
                                            pagination={false}
                                            customStyles={TABLE_STYLE} />
                                    </div>
                                </div>
                                :
                                <div className="mb-5">
                                    <EmptyScreen title="Empty user data!" />
                                </div>
                    }
                </div>
            </div>
            {
                openCreateModal === true &&
                <CreateRMModal type={tabIndex}
                    data={selectedData}
                    zoneList={zoneList}
                    heading={modalTitle}
                    updateList={_updateList}
                    close={_handleModalClose} />
            }
            {
                openDeleteModal === true &&
                <RemoveRMModal type={tabIndex}
                    id={selectedId}
                    zoneList={zoneList}
                    remapAvailable={remapAvailable}
                    close={() => { setOpenDeleteModal(false); setSelectedId(null); setRemapAvailable(false) }}
                    confirmDelete={_reloadData} />
            }
        </>
    )
}
